const g:any = window[ENV.projectName] = window[ENV.projectName] || {};

import { Component, Vue, Emit, Ref } from 'vue-property-decorator';
import { mainStore } from '../_store/main';

const EMAIL_REGEXP = /^([a-zA-Z0-9])+([a-zA-Z0-9\._-])*@([a-zA-Z0-9_-])+([a-zA-Z0-9\._-]+)+$/;
const TEL_REGEXP = /^(0{1}\d{1,4}-?\d{1,4}-?\d{4})$/;

const KANA_MAP = {
  'ｶﾞ': 'ガ', 'ｷﾞ': 'ギ', 'ｸﾞ': 'グ', 'ｹﾞ': 'ゲ', 'ｺﾞ': 'ゴ',
  'ｻﾞ': 'ザ', 'ｼﾞ': 'ジ', 'ｽﾞ': 'ズ', 'ｾﾞ': 'ゼ', 'ｿﾞ': 'ゾ',
  'ﾀﾞ': 'ダ', 'ﾁﾞ': 'ヂ', 'ﾂﾞ': 'ヅ', 'ﾃﾞ': 'デ', 'ﾄﾞ': 'ド',
  'ﾊﾞ': 'バ', 'ﾋﾞ': 'ビ', 'ﾌﾞ': 'ブ', 'ﾍﾞ': 'ベ', 'ﾎﾞ': 'ボ',
  'ﾊﾟ': 'パ', 'ﾋﾟ': 'ピ', 'ﾌﾟ': 'プ', 'ﾍﾟ': 'ペ', 'ﾎﾟ': 'ポ',
  'ｳﾞ': 'ヴ', 'ﾜﾞ': 'ヷ', 'ｦﾞ': 'ヺ',
  'ｱ': 'ア', 'ｲ': 'イ', 'ｳ': 'ウ', 'ｴ': 'エ', 'ｵ': 'オ',
  'ｶ': 'カ', 'ｷ': 'キ', 'ｸ': 'ク', 'ｹ': 'ケ', 'ｺ': 'コ',
  'ｻ': 'サ', 'ｼ': 'シ', 'ｽ': 'ス', 'ｾ': 'セ', 'ｿ': 'ソ',
  'ﾀ': 'タ', 'ﾁ': 'チ', 'ﾂ': 'ツ', 'ﾃ': 'テ', 'ﾄ': 'ト',
  'ﾅ': 'ナ', 'ﾆ': 'ニ', 'ﾇ': 'ヌ', 'ﾈ': 'ネ', 'ﾉ': 'ノ',
  'ﾊ': 'ハ', 'ﾋ': 'ヒ', 'ﾌ': 'フ', 'ﾍ': 'ヘ', 'ﾎ': 'ホ',
  'ﾏ': 'マ', 'ﾐ': 'ミ', 'ﾑ': 'ム', 'ﾒ': 'メ', 'ﾓ': 'モ',
  'ﾔ': 'ヤ', 'ﾕ': 'ユ', 'ﾖ': 'ヨ',
  'ﾗ': 'ラ', 'ﾘ': 'リ', 'ﾙ': 'ル', 'ﾚ': 'レ', 'ﾛ': 'ロ',
  'ﾜ': 'ワ', 'ｦ': 'ヲ', 'ﾝ': 'ン',
  'ｧ': 'ァ', 'ｨ': 'ィ', 'ｩ': 'ゥ', 'ｪ': 'ェ', 'ｫ': 'ォ',
  'ｯ': 'ッ', 'ｬ': 'ャ', 'ｭ': 'ュ', 'ｮ': 'ョ',
  '｡': '。', '､': '、', 'ｰ': 'ー', '-': 'ー', '｢': '「', '｣': '」', '･': '・'
};
const KANA_REPLACE_REGEXP = new RegExp('(' + Object.keys(KANA_MAP).join('|') + ')', 'g');
const KANA_REGEXP = new RegExp('^[' + Object.values(KANA_MAP).concat([' ', '　', '・','ー','＝']).join('') + ']+$');

export type ValidationSetting = {
  type: 'required' | 'email' | 'tel'| 'length' | 'equal' | 'kana',
  length?: number,
  equalTo?: string,
  errorMsg: string,
}

export type ValidationError = ValidationSetting | null;

@Component
export class FormItem extends Vue {
  protected isError: boolean = true;
  protected errorMsg: string = '';
  protected isFocusedOnce: boolean = false;
  protected value: string | boolean = '';
  protected validation: ValidationSetting[] = [];
  protected name: string = '';
  protected isRequired: boolean = false;
  protected equalCheckItem!: FormItem;

  protected h(str: string){
    return (str + '').replace(/&/g,'&amp;')
                     .replace(/"/g,'&quot;')
                     .replace(/'/g,'&#039;')
                     .replace(/</g,'&lt;')
                     .replace(/>/g,'&gt;');
  }

  protected nToBr(str: string){
    return str.replace(/[\n\r]/g, "\n").replace(/\n/g, "<br/>");
  }

  public setEqualCheckItem(equalCheckItem: FormItem) {
    this.equalCheckItem = equalCheckItem;
  }

  public getEqualValidation() {
    const arr = Object.values(this.validation).filter((k)=> k.type === 'equal');
    return (arr?.length === 1)? arr[0]: null;
  }

  get classObj() {
    return {
      'is-error': this.isError && this.isFocusedOnce
    }
  }

  public getDisplayValue() {
    return this.nToBr(this.h(this.value as string));
  }

  @Emit('validate')
  protected onValidate() {
    return { name: this.name, error: this.isError };
  }

  public setValidation(validation: ValidationSetting[]) {
    this.validation = validation;
  }

  mounted() {
    this.name = this.$el.getAttribute('data-form-name') as string;
    this.isRequired = this.validation.findIndex((setting)=> setting.type === 'required') !== -1;
  }

  protected validateRequired(errorMsg: string): ValidationError {
    if(typeof this.value === 'string' && this.value.replace(/[\t\s\r\n]/g, '') === '') {
      return { type: 'required', errorMsg }
    } else if(this.value === false) {
      return { type: 'required', errorMsg }
    }
    return null;
  }

  protected validateEqual(errorMsg: string): ValidationError {
    if(this.value !== this.equalCheckItem.getValue()) {
      return { type: 'equal', errorMsg }
    }
    return null;
  }

  protected validateLength(length: number, errorMsg: string): ValidationError {
    if(typeof this.value === 'string' && this.value.length > length) {
      return { type: 'length', length, errorMsg };
    }
    return null;
  }

  protected validateEmail(errorMsg: string): ValidationError {
    this.value = this.zenToHan(this.value as string);
    if(typeof this.value === 'string' && !this.value.match(EMAIL_REGEXP)) {
      return { type: 'email', errorMsg }
    }
    return null
  }

  protected validateTel(errorMsg: string): ValidationError {
    this.value = this.zenToHan(this.value as string);
    if(typeof this.value === 'string' && !this.value.match(TEL_REGEXP)) {
      return { type: 'tel', errorMsg }
    }
    return null
  }

  protected hiraToKana(str) {
    return str.replace(/[\u3041-\u3096]/g, (match)=> {
      var chr = match.charCodeAt(0) + 0x60;
      return String.fromCharCode(chr);
    });
  }

  protected hanToZenKana(str) {
    return str.replace(KANA_REPLACE_REGEXP, (match)=> {
      return KANA_MAP[match];
    })
    .replace(/ﾞ/g, '゛')
    .replace(/ﾟ/g, '゜');
  }

  protected zenToHan(str) {
    // 入力値の全角を半角の文字に置換
    return str
    .replace(/[Ａ-Ｚａ-ｚ０-９！＂＃＄％＆＇（）＊＋，－．／：；＜＝＞？＠［＼］＾＿｀｛｜｝]/g, (s)=> {
      return String.fromCharCode(s.charCodeAt(0) - 0xfee0);
    })
    .replace(/[‐－―ー]/g, "-") // ハイフンなど
    .replace(/[～〜]/g, "~") // チルダ
    .replace(/　/g, " "); // スペース
  }


  protected validateKana(errorMsg: string): ValidationError {
    this.value = this.hiraToKana(this.value as string);
    this.value = this.hanToZenKana(this.value as string);
    if(!(this.value as string).match(KANA_REGEXP)) {
      return { type: 'kana', errorMsg }
    }
    return null
  }

  public validate(forceErrorDisplay: boolean = false) {
    if(forceErrorDisplay) this.isFocusedOnce = true;
    let error: ValidationError;
    for (let i = 0, l = this.validation.length; i < l; i++) {
      if(error = this.validateItem(this.validation[i])) {
        this.isError = true;
        this.errorMsg = error.errorMsg;
        this.onValidate();
        return;
      }
    }
    this.isError = false;
    // this.errorMsg = '';
    this.onValidate();
  }

  protected validateItem(setting: ValidationSetting) {
    if(setting.type === 'required') {
      return this.validateRequired(setting.errorMsg);

    } else if(setting.type === 'email') {
      return this.validateEmail(setting.errorMsg);

    } else if(setting.type === 'tel') {
      return this.validateTel(setting.errorMsg);

    } else if(setting.type === 'equal') {
      return this.validateEqual(setting.errorMsg);

    } else if(setting.type === 'length') {
      return this.validateLength(setting.length || 400, setting.errorMsg);

    } else if(setting.type === 'kana') {
      return this.validateKana(setting.errorMsg);

    }
    return null;
  }

  protected onChange(e: Event) {
    this.validate();
  }

  protected onFocus(e: Event) {
    this.isFocusedOnce = true;
  }

  protected onBlur(e: Event) {
    if(typeof this.value === 'string') this.value = this.value.trim();
    this.validate();
  }

  public getValue(): string {
    if(typeof this.value === 'string') return this.value;
    return this.value? '1': '0';
  }

  public setValue(value: string | boolean) {
    this.value = value;
    if(this.value !== '') {
      this.isFocusedOnce = true;
      this.validate();
    }
  }

  public getName() {
    return this.name;
  }

  public getError() {
    return this.isError;
  }
}

@Component
export default class Form extends Vue {
  protected isError: boolean = true;
  protected formItems: FormItem[] = [];
  protected isIlligalAccess: boolean = false;
  protected onResize!: ()=> void;

  get classObj() {
    return {
      'is-illigalAccess': this.isIlligalAccess
    }
  }

  get formClassObj() {
    return {
      'is-error': this.isError
    }
  }

  mounted() {
  }

  protected getOffsetTop(el: HTMLElement): number {
    if(el.offsetParent) {
      const offset = this.getOffsetTop(el.offsetParent as HTMLElement);
      return offset + el.offsetTop;
    }
    return el.offsetTop;
  }

  public async init(validations: { [key: string]: ValidationSetting[] }) {
    const promises: Promise<any>[] = [];
    const itemEls = this.$el.querySelectorAll<HTMLElement>('.js-formItem');
    const equalToItems: { item: FormItem, equalValidation: ValidationSetting }[] = [];

    for (let i = 0, l = itemEls.length; i < l; i++) {
      const item = new FormItem({ el: itemEls[i] });
      const input: HTMLInputElement | HTMLTextAreaElement | null = itemEls[i].querySelector('input, textarea');
      item.setValidation(validations[item.getName()]);
      if(input?.value) item.setValue(input.value);
      item.$on('validate', (data: { name: string, error: boolean })=> {
        this.onValidate();
      });
      const equalValidation = item.getEqualValidation();
      if(equalValidation) equalToItems.push({ item, equalValidation });
      this.formItems.push(item);
      promises.push(item.$nextTick());
    }

    for (let i = 0, l = equalToItems.length; i < l; i++) {
      const { item, equalValidation }  = equalToItems[i];
      item.setEqualCheckItem(this.getItemByName(equalValidation.equalTo as string) as FormItem);
    }
  }

  protected onValidate() {
    let isError = false;

    for (let i = 0, l = this.formItems.length; i < l; i++) {
      if(this.formItems[i].getError()) {
        isError = true;
        break;
      }
    }

    this.isError = isError;
  }

  protected getItemByName(name: string) {
    for (let i = 0, l = this.formItems.length; i < l; i++) {
      const item = this.formItems[i];
      if(item.getName() === name) {
        return item;
      }
    }
    return null;
  }

  protected onClickSubmit(e: Event) {
    if(!this.isError) {
      console.log('submit!!!');
      e.preventDefault();
      e.stopImmediatePropagation();
      (this.$el as HTMLFormElement).submit();
    }
  }
}