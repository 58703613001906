
const g:any = window[ENV.projectName] = window[ENV.projectName] || {};

import { Component, Vue, Ref, Emit } from 'vue-property-decorator';

import { mainStore } from '../_store/main';
import PageComponent from '../_modules/PageComponent';

import MainGL from '../_modules/webgl/MainGL';
import { PointCloudDefinition } from '../_modules/webgl/PointCloud';
import Form, { ValidationSetting } from '../_modules/Form';

const VALIDATIONS: { [key: string]: ValidationSetting[] } = {
  name: [
    { type: 'required', errorMsg: 'お名前を入力してください。' },
    { type: 'length', length: 400, errorMsg: '400文字以内で入力してください。' },
  ],
  nameKana: [
    { type: 'required', errorMsg: 'お名前 (フリガナ)を入力してください。' },
    { type: 'kana', errorMsg: '全角カタカナで入力してください。' },
    { type: 'length', length: 400, errorMsg: '400文字以内で入力してください。' },
  ],
  email: [
    { type: 'required', errorMsg: 'メールアドレスを入力してください。' },
    { type: 'email', errorMsg: '正しい形式で入力してください。' },
    { type: 'length', length: 400, errorMsg: '400文字以内で入力してください。' },
  ],
  emailConfirm: [
    { type: 'required', errorMsg: 'メールアドレス (確認用)を入力してください。' },
    { type: 'email', errorMsg: '正しい形式で入力してください。' },
    { type: 'equal', equalTo: 'email', errorMsg: '入力された内容が異なります。' },
    { type: 'length', length: 400, errorMsg: '400文字以内で入力してください。' },
  ],
  tel: [
    { type: 'required', errorMsg: '電話番号を入力してください。' },
    { type: 'tel', errorMsg: '正しい形式で入力してください。' },
    { type: 'length', length: 20, errorMsg: '20文字以内で入力してください。' },
  ],
  address: [
    { type: 'required', errorMsg: '電話番号を入力してください。' },
    { type: 'length', length: 400, errorMsg: '400文字以内で入力してください。' },
  ],
  message: [
    { type: 'required', errorMsg: '採用担当者へ一言を入力してください。' },
    { type: 'length', length: 5000, errorMsg: '5000文字以内で入力してください。' },
  ],
};


export default class Main extends PageComponent {
  protected mainGL!: MainGL<PointCloudDefinition>;

  constructor() {
    super();
    this.init();
  }

  protected async initMainGL() {
    const mainGLEl = this.bodyElm.querySelector('.js-mainGL') as HTMLElement;

    this.mainGL = new MainGL(
      mainGLEl,
      mainGLEl.querySelector('canvas') as HTMLCanvasElement,
      null,
      ''
    );

    await this.mainGL.init()
    .catch((e)=> {
      console.log(e);
      this.htmlElm.classList.add('is-noWebGL');
      mainStore.setNoWebGL(true);
    })
    this.mainGL.onResize();
  }

  protected async initContents(): Promise<void> {
    await Promise.all([
      super.initContents(),
      this.initForm(),
      this.initMainGL()
    ]);
  }

  protected async init(): Promise<void> {
    mainStore.setCurrentPageId('entry');
    await super.init();
    this.isLocked = false;
    this.play();
  }

  protected async onResize() {
    this.mainGL.onResize();
  }

  protected onScroll(): void {

  }

  protected async initForm() {
    const form = new Form({
      el: this.bodyElm?.querySelector('.js-form') as HTMLElement
    });
    await form.$nextTick();
    await form.init(VALIDATIONS);
  }

  public play(): void {
    this.mainGL.startLoop();
    this.mainGL.play();
    super.play();
  }
}